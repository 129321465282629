import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import { linkResolver } from '../../utils/linkResolver'
import { colorBackground } from '../../utils/colorBackground'

import ArrowLink from '../arrowLink/arrowLink'
 
const SingleColumnText = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <section className={`section-slim ${colorBackground(props.section.primary.background_colour)}`}>
      <div className="cols-wrapper">
        <div className="cols">
          <div className="col-10-md push-1-md col-10-lg push-1-lg">
            {!!props.section.primary.section_title &&
              <h2 className={`t-h2 section-title ${props.section.primary.background_colour === 'Blue' && 't-color-white'}`}>{RichText.asText(props.section.primary.section_title)}</h2>
            }
            {!!props.section.primary.section_text &&
              <div className={`t-h3 text-body`}>
                {RichText.render(props.section.primary.section_text)}
              </div>
            }
            {!!props.section.primary.learn_more_linked_content &&
              <div className="button-block">
                <ArrowLink
                  to={linkResolver(props.section.primary.learn_more_linked_content._meta)}
                  color={props.section.primary.background_colour === 'Blue' ? 'grey' : 'blue'}
                >
                  Learn More
                </ArrowLink>
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

SingleColumnText.propTypes = {
  section: PropTypes.object
}

SingleColumnText.defaultProps = {
  section: {}
}
 
export default SingleColumnText