import React from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types"

import styles from './newsGrid.module.scss'
 
const NewsGridImage = ( props ) => {
  const internal = /^\/(?!\/)/.test(props.to)

  if (internal) {
    return (
      <Link
        to={props.to}
        className={styles.imageWrap}
      >
        {props.children}
      </Link>
    )
  }
  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.imageWrap}
    >
      {props.children}
    </a>
  )
}

NewsGridImage.propTypes = {
  to: PropTypes.string
}
 
export default NewsGridImage;