export const colorBackground = function (color) {
  switch (color) {
    case "White":
      return "bg-white"
    case "Grey":
      return "bg-grey"
    case "Blue":
      return "bg-blue t-color-white"
    default:
      return "bg-white"
  }
}
 