import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import HomepageSlideshow from '../components/homepageSlideshow/homepageSlideshow'
import HomepageContent from '../components/homepageContent/homepageContent'
import NewsGrid from '../components/newsGrid/newsGrid'

export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          services {
            service {
              ... on PRISMIC_Services {
                title
                introduction_text
                background_video {
                  ... on PRISMIC__ImageLink {
                    _linkType
                    url
                    width
                    height
                  }
                  ... on PRISMIC__FileLink {
                    _linkType
                    url
                  }
                }
                _meta {
                  uid
                  type
                }
              }
            }
          }
          body {
            ... on PRISMIC_HomepageBodySingle_column_text {
              type
              label
              primary {
                background_colour
                learn_more_linked_content {
                  _linkType
                  ... on PRISMIC_About {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Contact {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Text_page {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Partners {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Sustainability {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Team {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Commodities {
                    _meta{
                      type
                      uid
                    }
                  }
                }
                section_text
                section_title
              }
            }
            ... on PRISMIC_HomepageBodyServices_columns {
              type
              label
              primary {
                background_colour
              }
              fields {
                service {
                  ... on PRISMIC_Services {
                    title
                    introduction_text
                    brand_colour
                    _meta {
                      uid
                      type
                    }
                  }
                }
                background_image
              }
            }
            ... on PRISMIC_HomepageBodyGraphs___data {
              type
              label
              primary {
                section_title
              }
            }
            ... on PRISMIC_HomepageBodyText_columns {
              type
              label
              primary {
                section_title
              }
              fields {
                column_text
                column_title
              }
            }
            ... on PRISMIC_HomepageBodyQuote {
              type
              label
              primary {
                quote_text
              }
            }
            ... on PRISMIC_HomepageBodyBackground_image {
              type
              label
              primary {
                image
                section_text
                section_title
                learn_more_linked_content {
                  _linkType
                  ... on PRISMIC_About {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Contact {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Text_page {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Partners {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Sustainability {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Team {
                    _meta{
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Commodities {
                    _meta{
                      type
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allNews_articless(first: 3, sortBy: post_date_DESC) {
      edges {
        node {
          title
          post_excerpt
          thumbnail
          _meta {
            uid
            type
          }
          external_article_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
            }
          }
        }
      }
    }
  }
}`

const IndexPage = ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0,1).pop();
  const newsPosts = data.prismic.allNews_articless.edges;
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Concord" titleTemplate={false} description="Concord Resources is a commodity merchant trader with a focus on non-ferrous metals and associated minerals." />
      <HomepageSlideshow slides={doc.node.services} />
      <HomepageContent doc={doc} />
      <div className="section-wide">
        <div className="limit-width">
          <div className="section-header">
            <h3 className="t-h2 section-title">News & Media</h3>
          </div>
          <NewsGrid articles={newsPosts.map(post => post.node)} feature={false} />
          <div className="button-block button-block-center">
            <Link
              to="/news-media"
              className="button t-color-blue"
            >
              See more
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default IndexPage
