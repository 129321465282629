import React, { Component } from 'react'
import PropTypes from "prop-types"
import gsap from 'gsap'
import VisibilitySensor from 'react-visibility-sensor'

import styles from './graphsData.module.scss'

class NumChart extends Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.state = {
      num: 0,
      endNum: 2.9,
      hasSeen: false
    }
  }

  onChange = (isVisible) => {
    // console.log(this.state.hasSeen === false, isVisible === true)

    if (this.state.hasSeen === false && isVisible === true) {
      const number = {
        val: 0
      }

      gsap.to(number, {duration: 1, delay: 0.25, ease: "power1.out", val: this.state.endNum, onUpdate: () => {
        this.setState({ num: number.val.toFixed(1) })
      }})

      this.setState({ hasSeen: true })
    }
  }

  render () {
    return (
      <VisibilitySensor onChange={this.onChange}>
        <div className={styles.numChart}>
          <div className={styles.numChartInner}>
            <span ref={this.canvasRef} className={styles.numValue}>{this.state.num}</span>
            <span className={styles.numLabel}>M</span>
          </div>
        </div>
      </VisibilitySensor>
    )
  }
}

NumChart.propTypes = {
  section: PropTypes.object
}
 
export default NumChart