import React, { Component } from 'react'
import PropTypes from "prop-types"

import Chart from 'chart.js'

import styles from './graphsData.module.scss'

require('chartjs-plugin-deferred')

class PolarChart extends Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.state = {
      chart: null,
      ctx: null
    }
  }

  componentDidMount () {
    const ctx = this.canvasRef.current.getContext('2d')

    const chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'polarArea',

        // The data for our dataset
        data: {
          labels: ['2019', '2020', '2021', '2022'],
          datasets: [{
            label: '',
            backgroundColor: ['#A5AEB3', '#748493', '#3B576D', '#1A2639'],
            data: [4.3, 4.3, 5.4, 4.2]
          }]
        },

        // Configuration options go here
        options: {
          aspectRatio: 1,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          scale: {
            display: false
          },
          plugins: {
            deferred: {
              xOffset: '50%',
              yOffset: '50%',
              delay: 250 
            }
          }
        }
    })

    this.setState({ chart: chart })
    this.setState({ ctx: ctx })
  }

  render () {
    return (
      <div className={styles.chart}>
        <div className={styles.chartInner}>
          <canvas ref={this.canvasRef} />
        </div>
      </div>
    )
  }
}

PolarChart.propTypes = {
  section: PropTypes.object
}
 
export default PolarChart