import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import styles from './graphsData.module.scss'

import BarChart from './barChart'
import LineChart from './lineChart'
import PointChart from './pointChart'
import PieChart from './pieChart'
import PolarChart from './polarChart'
import NumChart from './numChart'
import worldMap from './world-map.png';

const GraphsData = ( props ) => {
  return (
    <section className={`section-slim ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className="limit-width">
          {!!props.section.primary.section_title &&
            <h2 className={`t-h2 section-title`}>{RichText.asText(props.section.primary.section_title)}</h2>
          }
        </div>
        <div className={styles.gridWrapper}>
          <div className={styles.grid}>
            <div className={`${styles.dataSingle}`}>
              <PolarChart />
              <div className={styles.dataCaption}>
                <p className={`t-h3 t-color-blue t-semi ${styles.dataTitle}`}>
                  US$4.2bn
                </p>
                <p className={`t-body`}>
                  Group Revenue
                </p>
              </div>
            </div>

            <div className={`${styles.dataSingle}`}>
              <NumChart />
              <div className={styles.dataCaption}>
                <p className={`t-h3 t-color-blue t-semi ${styles.dataTitle}`}>
                  2.9m tonnes
                </p>
                <p className={`t-body`}>
                  Traded sales volumes
                </p>
              </div>
            </div>

            <div className={`${styles.dataSingle}`}>
              <BarChart />
              <div className={styles.dataCaption}>
                <p className={`t-h3 t-color-blue t-semi ${styles.dataTitle}`}>
                  US$2.1bn
                </p>
                <p className={`t-body`}>
                  Total Assets
                </p>
              </div>
            </div>
            
            <div className={`${styles.dataSingle}`}>
              <LineChart />
              <div className={styles.dataCaption}>
                <p className={`t-h3 t-color-blue t-semi ${styles.dataTitle}`}>
                  US$434.6m
                </p>
                <p className={`t-body`}>
                  Shareholders Equity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src={worldMap} className={styles.map} />
    </section>
  )
}

GraphsData.propTypes = {
  section: PropTypes.object
}

GraphsData.defaultProps = {
  section: {}
}
 
export default GraphsData