import React from "react"
import PropTypes from "prop-types"

import SingleColumnText from '../../components/singleColumnText/singleColumnText'
import ServicesColumns from '../../components/servicesColumns/servicesColumns'
import GraphsData from '../../components/graphsData/graphsData'
import Quote from '../../components/quote/quote'
import TextColumns from '../../components/textColumns/textColumns'
import BackgroundImage from '../../components/backgroundImage/backgroundImage'

const HomepageContent = ({ doc }) => {
  return (
    <React.Fragment>
      {doc.node.body !== null ? doc.node.body.map((section, index) => {
        switch (section.type) {
          case 'single_column_text':
            return (
              <SingleColumnText section={section} key={index} />
            )
          case 'services_columns':
            return (
              <ServicesColumns section={section} key={index} />
            )
          case 'graphs___data':
            return (
              <GraphsData section={section} key={index} />
            )
          case 'quote':
            return (
              <Quote section={section} key={index} />
            )
          case 'text_columns':
            return (
              <TextColumns section={section} key={index} />
            )
          case 'background_image':
            return (
              <BackgroundImage section={section} key={index} />
            )
          default:
            return null
        }
      }) : null}
    </React.Fragment>
  )
}

HomepageContent.propTypes = {
  doc: PropTypes.object
}

export default HomepageContent