import React, { useState, useEffect, useCallback } from 'react'
import Swiper from 'react-id-swiper'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import VisibilitySensor from 'react-visibility-sensor'
import { connect } from 'react-redux'
import {gsap} from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin.js'
  
import { linkResolver } from '../../utils/linkResolver'
import { renderBackground } from '../../utils/renderBackground'

import ArrowLink from '../arrowLink/arrowLink'

import 'swiper/swiper.scss'
import styles from './homepageSlideshow.module.scss'
 
const Slideshow = ( props ) => {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, updateCurrentIndex] = useState(0)
  const duration = 5000
  const speed = 500

  const params = {
    containerClass: styles.slideshow,
    slideClass: styles.slide, 
    wrapperClass: styles.wrapper, 
    loop: true,
    autoplay: {
      delay: duration,
      disableOnInteraction: false
    },
    speed: speed
  }

  gsap.registerPlugin(ScrollToPlugin)

  function scrollToContent (container, e) {
    if (!container) {
      return
    }

    const parent = e.currentTarget.closest(`.${container}`)

    if (!parent) {
      return
    }

    gsap.to(window, {duration: 1, scrollTo: {y: parent.clientHeight, offsetY: props.headerHeight, autoKill: false}, ease: "power2.inOut"})
  }

  const goToSlide = (index) => {
    // console.log(index)

    if (swiper !== null) {
      swiper.slideTo(index + 1)
    }
  }

  const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
    swiper
  ])

  useEffect(() => {
    props.setHeaderTransparent(true)   

    if (swiper !== null) {
      swiper.on("slideChange", updateIndex)
    }

    return () => {
      props.setHeaderTransparent(false)   

      if (swiper !== null) {
        swiper.off("slideChange", updateIndex)
      }
    }
  }, [swiper, updateIndex])

  function onChange (isVisible) {    
    props.setHeaderTransparent(isVisible)
  }
 
  return(
    <VisibilitySensor onChange={onChange} scrollCheck={true} intervalCheck={false} partialVisibility={true} offset={{top: props.headerHeight + 1, bottom: 0}} scrollThrottle={50}>
      <div className={`${styles.container}`}>
        <Swiper {...params} getSwiper={setSwiper}>
          {props.slides.map((item, index) => (
            <div key={index}>
              <div className={styles.slideInner}>
                <h2 className={styles.title}>
                  {RichText.asText(item.service.title)}
                </h2>
                <div className={`${styles.bodyText} t-h3 t-semi`}>
                  {RichText.render(item.service.introduction_text)}
                </div>
                <div className="swiper-no-swiping">
                  <ArrowLink
                    to={linkResolver(item.service._meta)}
                    color="white"
                  >
                    Learn more
                  </ArrowLink>
                </div>
              </div>
              {item.service.background_video &&
                <div className={styles.background}>
                  {renderBackground(item.service.background_video)}
                </div>
              }
            </div>
          ))}
        </Swiper>
        <div className={styles.pagination}>
          {props.slides.map((item, index) => (
            <button
              key={index}
              className={`${styles.paginationSingle} ${swiper !== null && currentIndex === index ? styles.paginationActive : ""}`}
              onClick={goToSlide.bind(this, index)}
            >
              <span className={styles.paginationLabel}>
                {RichText.asText(item.service.title)}
              </span>
              <span className={styles.paginationTimeline}>
                <span
                  className={`${styles.paginationTrack} ${swiper !== null && currentIndex === index ? styles.paginationAnimating : ""}`}
                  style={{
                    animationDuration: `${duration + speed}ms`
                  }}
                />
              </span>
            </button>
          ))}
        </div>
        <div className={`${styles.proceed} proceed-animate`}>
          <button className={styles.proceedButton} onClick={(e) => scrollToContent(styles.container, e)}>
            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L12.5 13.6962L24 1" stroke="white" strokeWidth="2"/>
            </svg>
          </button>
        </div>
      </div>
    </VisibilitySensor>
  )
}

Slideshow.propTypes = {
  slides: PropTypes.array,
  headerHeight: PropTypes.number
}

Slideshow.defaultProps = {
  slides: [],
  headerHeight: 0
}

const mapStateToProps = ({ headerHeight }) => {
  return { headerHeight }
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTransparent: (val) => dispatch({ type: `SET_HEADERTRANSPARENT`, value: val })
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Slideshow)