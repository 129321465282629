import React, { Component } from 'react'
import PropTypes from "prop-types"

import Chart from 'chart.js'

import styles from './graphsData.module.scss'

class PointChart extends Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.state = {
      chart: null,
      ctx: null,
      data: [1.25, 1.43, 1.57, 1.60]
    }
  }

  componentDidMount () {
    const ctx = this.canvasRef.current.getContext('2d')
    const chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'line',

        // The data for our dataset
        data: {
          labels: ['2017', '2018', '2019', '2020'],
          datasets: [{
            label: '',
            pointBorderWidth: 0,
            pointBorderColor: 'transparent',
            backgroundColor: 'transparent',
            fill: false,
            borderWidth: 1,
            borderColor: 'black',
            data: this.state.data,
            pointBackgroundColor: ['#A5AEB3', '#748493', '#3B576D', '#1A2639'],
            cubicInterpolationMode: 'monotone',
            pointRadius: 3,
          }]
        },

        // Configuration options go here
        options: {
          aspectRatio: 1,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          layout: {
            padding: 10
          },
          scales: {
            yAxes: [{
              id: 'left-y-axis',
              type: 'linear',
              position: 'left',
              ticks: {
                display: false
              },
              gridLines: {
                display: false
              }
            }, {
              id: 'right-y-axis',
              type: 'linear',
              position: 'right',
              ticks: {
                display: false
              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              display: false
            }]
          },
          plugins: {
            deferred: {
              xOffset: '50%',
              yOffset: '50%',
              delay: 250 
            }
          }
        }
    })

    this.setState({ chart: chart })
    this.setState({ ctx: ctx })
  }

  render () {
    return (
      <div className={styles.chart}>
        <div className={`${styles.chartInner}`}>
          <canvas ref={this.canvasRef} />
        </div>
      </div>
    )
  }
}

PointChart.propTypes = {
  section: PropTypes.object
}
 
export default PointChart