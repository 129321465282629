import React, { Component } from 'react'
import PropTypes from "prop-types"

import Chart from 'chart.js'

import styles from './graphsData.module.scss'

class BarChart extends Component {
  constructor(props) {
    super(props)

    this.canvasRef = React.createRef()

    this.state = {
      chart: null,
      ctx: null
    }
  }

  componentDidMount () {
    const ctx = this.canvasRef.current.getContext('2d')
    const chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'bar',

        // The data for our dataset
        data: {
          labels: ['2019', '2020', '2021', '2022'],
          datasets: [{
            label: '',
            backgroundColor: ['#A5AEB3', '#748493', '#3B576D', '#1A2639'],
            borderWidth: 0,
            data: [1.4, 1.4, 2.4, 2.1]
          }]
        },

        // Configuration options go here
        options: {
          aspectRatio: 1,
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          scales: {
            yAxes: [{
              id: 'left-y-axis',
              display: false,
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              display: false
            }]
          },
          plugins: {
            deferred: {
              xOffset: '50%',
              yOffset: '50%',
              delay: 250 
            }
          }
        }
    })

    this.setState({ chart: chart })
    this.setState({ ctx: ctx })
  }

  render () {
    return (
      <div className={styles.chart}>
        <div className={`${styles.chartInner} ${styles.barChart}`}>
          <canvas ref={this.canvasRef} />
        </div>
      </div>
    )
  }
}

BarChart.propTypes = {
  section: PropTypes.object
}
 
export default BarChart