import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import { colorBackground } from '../../utils/colorBackground'

import styles from './textColumns.module.scss'

const TextColumns = ( props ) => {
  return (
    <section className={`section-wide ${colorBackground(props.section.primary.background_colour)}`}>
      <div className="limit-width">
        {props.section.primary.section_title &&
          <h2 className={`t-h2 section-title`}>{RichText.asText(props.section.primary.section_title)}</h2>
        }
      </div>
      <div className="cols-wrapper">
        <div className="cols">
          {props.section.fields.map((item, index) => (
            <div
              key={index}
              className={`col-4-md col-4-lg ${styles.column}`}
            >
              <h2 className="t-h4">
                {RichText.asText(item.column_title)}
              </h2>
              <div className={`${styles.columnBody} t-body-1-lg t-body-2-sm`}>
                {RichText.render(item.column_text)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

TextColumns.propTypes = {
  section: PropTypes.object
}

TextColumns.defaultProps = {
  section: {}
}
 
export default TextColumns