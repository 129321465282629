import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
 
import Icon from "../../svg/concord-icon.svg"

import styles from './quote.module.scss'

const SingleColumnText = ( props ) => {
  if (props.section.primary.quote_text === null) {
    return null
  }

  return (
    <section className={`section-slim bg-blue ${styles.section}`}>
      <div className={styles.wrapper}>
        {props.section.primary.quote_text &&
          <blockquote className={`t-h2 section-title t-align-c t-color-white ${styles.quote}`}>
            <p>
              {RichText.asText(props.section.primary.quote_text)}
            </p>
          </blockquote>
        }
        <div className={styles.background}>
          <Icon />
        </div>
      </div>
    </section>
  )
}

SingleColumnText.propTypes = {
  section: PropTypes.object
}

SingleColumnText.defaultProps = {
  section: {}
}
 
export default SingleColumnText