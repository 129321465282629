import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'
import { linkResolver } from '../../utils/linkResolver'

import ArrowLink from '../arrowLink/arrowLink'

import styles from './backgroundImage.module.scss'

const SingleColumnText = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <section className={`section-75 t-color-white bg-blue ${styles.wrapper}`}>
      <div className={styles.container}>
        <div className="cols-wrapper">
          <div className="cols">
            <div className="col-10-md push-1-md col-8-lg push-2-lg">
              {!!props.section.primary.section_title &&
                <h2 className={`${styles.title} t-h2 t-color-white`}>{RichText.asText(props.section.primary.section_title)}</h2>
              }
              {!!props.section.primary.section_text &&
                <div className={`t-h3 t-semi text-body`}>
                  {RichText.render(props.section.primary.section_text)}
                </div>
              }
              {!!props.section.primary.learn_more_linked_content &&
                <div className="button-block-slim button-block-center--md">
                  <ArrowLink
                    to={linkResolver(props.section.primary.learn_more_linked_content._meta)}
                    color="white"
                  >
                    Learn More
                  </ArrowLink>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overlay} />
      {props.section.primary.image &&
        <div className={styles.image}>
          <div className={styles.imageClip}>
            <ResponsiveImage
              src={props.section.primary.image.url}
              width={props.section.primary.image.dimensions.width}
              height={props.section.primary.image.dimensions.height}
              alt={props.section.primary.image.alt}
              widths={['2200', '1500', '750']}
              parentFit="cover"
            />
          </div>
        </div>
      }
    </section>
  )
}

SingleColumnText.propTypes = {
  section: PropTypes.object
}

SingleColumnText.defaultProps = {
  section: {}
}
 
export default SingleColumnText