export const servicesColors = function (color) {
  switch (color) {
    case "Brand Colour 1":
      return "bg-brand-1"
    case "Brand Colour 2":
      return "bg-brand-2"
    case "Brand Colour 3":
      return "bg-brand-3"
    default:
      return "bg-blue"
  }
}
