import React from 'react'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
  
import { linkResolver } from '../../utils/linkResolver'
import ResponsiveImage from '../responsiveImage/responsiveImage'

import NewsGridImage from './newsGridImage'
import ArrowLink from '../arrowLink/arrowLink'

import styles from './newsGrid.module.scss'
 
const NewsGrid = ( props ) => (
  <div className={`${styles.grid} ${props.feature === true ? styles.feature : ''}`}>
    {props.articles.map((item, index) => (
      <div
        className={styles.item}
        key={index}
      >
        <div className={styles.image}>
          <NewsGridImage
            to={item.external_article_link !== null ? item.external_article_link.url : linkResolver(item._meta)}
          >
            {item.thumbnail !== null &&
              <ResponsiveImage
                src={item.thumbnail.url}
                width={item.thumbnail.dimensions.width}
                height={item.thumbnail.dimensions.height}
                alt={item.thumbnail.alt}
                widths={['1400', '900', '640']}
            />
            }
          </NewsGridImage>
        </div>
        <div className={styles.text}>
          <div>
            <div className={styles.title}>
              <h2 className="t-body t-semi t-color-blue">
                {RichText.asText(item.title)}
              </h2>
            </div>
            <div className={`${styles.excerpt} t-body text-body`}>
              {RichText.render(item.post_excerpt)}
            </div>
          </div>
          <div className={styles.button}>
            <ArrowLink
              to={item.external_article_link !== null ? item.external_article_link.url : linkResolver(item._meta)}
              className="button"
            >
              Read post
            </ArrowLink>
          </div>
        </div>
      </div>
    ))}
  </div>
)

NewsGrid.propTypes = {
  articles: PropTypes.array,
  feature: PropTypes.bool
}

NewsGrid.defaultProps = {
  articles: [],
  feature: false
}
 
export default NewsGrid;