import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import { linkResolver } from '../../utils/linkResolver'
import { servicesColors } from '../../utils/servicesColors'

import ArrowLink from '../arrowLink/arrowLink'

import styles from '../servicesColumns/servicesColumns.module.scss'

const ServicesColumns = ( props ) => {
  return (
    <section className={`${styles.section} bg-blue`}>
      <div className={`${styles.wrapper} cols-wrapper`}>
        <div className={`${styles.cols} cols`}>
          {props.section.fields.map((item, index) => (
            <div
              key={index}
              className={`${styles.column} col-4-md col-4-lg t-color-white ${servicesColors(item.service.brand_colour)}`}
            >
              <h2 className={`t-h4 t-color-white`}>
                {RichText.asText(item.service.title)} services
              </h2>
              <div className={`${styles.columnBody} t-body-1-lg t-body-2-sm`}>
                {RichText.render(item.service.introduction_text)}
              </div>
              <div className={styles.button}>
                <ArrowLink
                  to={linkResolver(item.service._meta)}
                  button="solid-white"
                >
                  Learn more
                </ArrowLink>
              </div>
              {item.background_image !== null &&
                <img
                  className={`${styles.backgroundImg} lazyload`}
                  src={item.background_image.url}
                  alt=""
                />
              }
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

ServicesColumns.propTypes = {
  section: PropTypes.object
}

ServicesColumns.defaultProps = {
  section: {}
}
 
export default ServicesColumns